<template>
  <section>
    <div class="row">
      <div class="col1">
        <div class="e-wallet-card wallet-bg">
          <div class="e-wallet-card-text">
            <p class="e-wallet">E-Wallet</p>
            <p class="e-amount" :style="balanceLoading ? 'opacity: 0' : 'opacity: 1'">
              <span v-if="accountType == 'commerce'">
                {{ walletDetails.walletBalance }}
              </span>
              <span v-else>
                {{ walletDetails.balance }}
              </span>
            </p>
            <p class="e-wallet" :style="balanceLoading ? 'opacity: 0' : 'opacity: 1'">
              <span v-if="accountType == 'commerce'">
                User ID - {{ walletDetails.walletAddress }}
              </span>
              <span v-else> User ID - {{ walletDetails.guid }} </span>
            </p>
            <img
              src="../../assets/images/e-naira-wallet-icon.png"
              alt="wallet-icon"
              class="wallet-icon"
              :class="balanceLoading ? 'rotate' : ''"
            />
          </div>
        </div>
        <div class="form-card">
          <div class="form-card-item mr" @click="$router.push('/e-naira-deposit')">
            <img
              src="../../assets/images/e-naira-purchase-icon.png"
              alt="purchase-icon"
              class="padding8"
            />
            <p class="form-card-text">Deposit</p>
          </div>
          <div class="form-card-item" @click="$router.push('/e-naira-withdrawal')">
            <img
              src="../../assets/images/e-naira-withdraw-icon.png"
              alt="purchase-icon"
              class="padding8"
            />
            <p class="form-card-text">Withdraw</p>
          </div>
        </div>
      </div>
      <div class="col2 table-border">
        <p class="t-history">Transaction History</p>
        <!-- Loadin Transaction -->
        <div v-if="loading">
          <img
            src="../../assets/images/e-naira-wallet-icon.png"
            alt="sterling-icon"
            class="sterling-icon-holder"
            :class="loading ? 'rotate' : ''"
          />
        </div>
        <!-- Empty Transaction -->
        <div v-if="!loading">
          <div v-if="transactions.edges.length == 0">
            <h3 class="empty-head">No Transaction</h3>
            <p class="empty">You haven't made any transactions yet</p>
          </div>
        </div>
        <div v-if="!loading">
          <table v-if="transactions.edges.length > 0">
            <thead>
              <tr>
                <th class="thcell">Amount</th>
                <th class="thcell">Account Number</th>
                <th class="thcell">Date</th>
              </tr>
            </thead>
            <div></div>
            <tbody style="margin: 20px">
              <tr v-for="transaction in transactionPaginate" :key="transaction.index">
                <td
                  :style="
                    transaction.node.type == 'withdrawal'
                      ? 'border-left: 1px solid #db353a; border-top: 1px solid #db353a; border-bottom: 1px solid #db353a; color:#db353a'
                      : 'border-left: 1px solid #039c03; border-top: 1px solid #039c03; border-bottom: 1px solid #039c03; color:#039c03'
                  "
                  class="tcell"
                >
                  {{
                    Math.abs(transaction.node.amount).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })
                  }}
                </td>
                <td
                  :style="
                    transaction.node.type == 'withdrawal'
                      ? 'border-top: 1px solid #db353a; border-bottom: 1px solid #db353a'
                      : 'border-top: 1px solid #039c03; border-bottom: 1px solid #039c03'
                  "
                  class="tcell"
                >
                  {{ transaction.node.guid }}
                </td>
                <td
                  :style="
                    transaction.node.type == 'withdrawal'
                      ? 'border-top: 1px solid #db353a;border-bottom: 1px solid #db353a;border-right: 1px solid #db353a;'
                      : 'border-top: 1px solid #039c03;border-bottom: 1px solid #039c03;border-right: 1px solid #039c03;'
                  "
                  class="tcell"
                >
                  {{ new Date(transaction.node.insertedAt).toLocaleString() }}
                </td>
              </tr>
            </tbody>
          </table>
          <sliding-pagination
            :current="currentPage"
            :total="total"
            :page="totalPages"
            @page-change="pageChangeHandler"
          ></sliding-pagination>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import api from "@/api/api.js";
import SlidingPagination from "vue-sliding-pagination";

export default {
  components: {
    SlidingPagination,
  },
  data() {
    return {
      transactions: { edges: [] },
      transactionPaginate: [],
      walletDetails: { walletBalance: "0.00", walletAddress: "" },
      interval: null,
      loading: true,
      balanceLoading: true,
      accountType: "",
      currentPage: 1,
      totalPages: 5,
    };
  },
  computed: {
    total() {
      let page = this.transactions.edges.length / this.totalPages;
      return Math.ceil(page);
    },
  },
  methods: {
    pageChangeHandler(selectedPage) {
      this.currentPage = selectedPage;
      let end = this.currentPage * this.totalPages;
      let start = end - this.totalPages;
      this.transactionPaginate = this.transactions.edges.slice(start, end);
    },
    getCommerceBalance() {
      this.balanceLoading = true;
      this.loading = true;
      let payload = {
        token: this.$store.state.eNairaUserData.enairaUser.token,
      };
      api
        .getCommerceBalance(payload)
        .then((response) => {
          let responseMessage = response.Message;
          if (responseMessage == "Request Processed Successfully") {
            this.walletDetails = response.Data;
            this.$store.commit("seteNairaUserBalance", this.walletDetails);
            this.balanceLoading = false;
          } else {
            this.$message({
              showClose: true,
              message: `${
                responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
              }`,
              type: "error",
              duration: 10000,
            });
          }
        })
        .catch((error) => {
          this.balanceLoading = false;
          this.loading = false;
          this.$message({
            showClose: true,
            message: `Error getting user balance`,
            type: "error",
            duration: 10000,
          });
          throw error;
        });
    },
    getMerchantBalance() {
      this.balanceLoading = true;
      this.loading = true;
      let payload = {
        token: this.$store.state.eNairaUserData.enairaUser.token,
      };
      api
        .eNairaMerchantBalance(payload)
        .then((response) => {
          // console.log(response, "USER CHECK");
          let responseMessage = response.Message;
          if (responseMessage == "Request Processed Successfully") {
            this.walletDetails = response.Data;
            this.$store.commit("seteNairaUserBalance", this.walletDetails);
            this.balanceLoading = false;
          } else {
            this.$message({
              showClose: true,
              message: `${
                responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
              }`,
              type: "error",
              duration: 10000,
            });
          }
        })
        .catch((error) => {
          this.balanceLoading = false;
          this.loading = false;
          this.$message({
            showClose: true,
            message: `Error getting user balance`,
            type: "error",
            duration: 10000,
          });
          throw error;
        });
    },
    getCommerceHistory() {
      this.loading = true;
      let payload = {
        token: this.$store.state.eNairaUserData.enairaUser.token,
        numberOfRecord: 10,
      };
      api
        .getCommerceHistory(payload)
        .then((response) => {
          let responseMessage = response.Message;
          if (responseMessage == "Request Processed Successfully") {
            this.transactions = response.Data;
            this.pageChangeHandler(1);
            this.loading = false;
          } else {
            this.$message({
              showClose: true,
              message: `${
                responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
              }`,
              type: "error",
              duration: 10000,
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: `Error getting user transaction history`,
            type: "error",
            duration: 10000,
          });
          throw error;
        });
    },
    getMerchantHistory() {
      this.loading = true;
      let payload = {
        token: this.$store.state.eNairaUserData.enairaUser.token,
        numberOfRecord: 10,
      };
      api
        .getMerchantHistory(payload)
        .then((response) => {
          let responseMessage = response.Message;
          if (responseMessage == "Request Processed Successfully") {
            this.transactions = response.Data;
            this.loading = false;
          } else {
            this.$message({
              showClose: true,
              message: `${
                responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
              }`,
              type: "error",
              duration: 10000,
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: `Error getting user transaction history`,
            type: "error",
            duration: 10000,
          });
          throw error;
        });
    },
  },
  mounted() {
    if (this.$store.state.eNairaUserData.accountType == "commerce") {
      this.accountType = "commerce";
      this.interval = setInterval(() => {
        if (this.$store.state.eNairaUserData != undefined || null) {
          if (this.$store.state.eNairaUserData.accountType == "commerce") {
            this.getCommerceBalance();

            setTimeout(() => {
              this.getCommerceHistory();
            }, 200);
          }
          clearInterval(this.interval);
        }
      }, 1000);
    } else {
      this.accountType = "merchant";
      this.interval = setInterval(() => {
        if (this.$store.state.eNairaUserData != undefined || null) {
          if (this.$store.state.eNairaUserData.accountType == "merchant") {
            this.getMerchantBalance();

            setTimeout(() => {
              this.getMerchantHistory();
            }, 200);
          }
          clearInterval(this.interval);
        }
      }, 1000);
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_form.scss";
@import "~vue-sliding-pagination/dist/style/vue-sliding-pagination.css";

.e-wallet-card {
  width: 100%;
}
.e-wallet-card-text {
  padding: 22px;
  width: 100%;
}

.e-wallet {
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  color: #09ff09;
}
.e-amount {
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 41px;
  color: #ffffff;
  padding-top: 18px;
  padding-bottom: 10px;
}

.wallet-bg {
  background: radial-gradient(73.28% 402.18% at 100% 4.55%, #02613f 0%, #013d27 94.88%);
  border-radius: 10px;
  padding-bottom: 10px;
  position: relative;
}
.wallet-icon {
  position: absolute;
  top: 10px;
  right: 18px;
}

.form-card {
  display: flex;
  margin-top: 15px;
  margin-bottom: 30px;
}
.mr {
  margin-right: 15px;
}
.form-card-item {
  width: 50%;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
  height: 100%;
  padding: 30px;
  cursor: pointer;
}

.table-border {
  background: #fafafa;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px;
}
.t-history {
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  padding-bottom: 18px;
}
.thcell {
  font-family: Red Hat Display;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  padding: 18px 2px 18px 18px;
}

.tcell {
  font-family: Red Hat Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  padding: 15px 5px 15px 15px;
}

table {
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 8px;
}
thead {
  width: 100%;
  background: #333333;
  margin-bottom: 15px;
}
table th:first-child {
  border-radius: 10px 0 0 10px;
}
table th:last-child {
  border-radius: 0 10px 10px 0;
}

table td:first-child {
  border-radius: 10px 0 0 10px;
}
table td:last-child {
  border-radius: 0 10px 10px 0;
}

tbody {
  border: 1px solid #e0e0e0;
  background: #ffffff;
}

.tr {
  margin: 10px 10px;
}

.padding8 {
  padding-bottom: 8px;
}

.form-card-text {
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
}

.empty-head {
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 16px;
  text-align: center;
  padding: 2rem;
  padding-top: 4rem;
  color: #333333;
}
.empty {
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #333333;
}
.sterling-icon-holder {
  display: block;
  margin: 7rem auto 4rem;
}
.rotate {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@media only screen and (max-width: 600px) {
  .row {
    display: block;
    margin-top: 1.5rem;
  }
  .col1 {
    width: 100%;
  }
  .col2 {
    width: 100%;
  }
  .thcell {
    font-size: 12px;
    color: #fff;
    padding: 10px 3px 10px 10px;
  }

  .tcell {
    font-size: 12px;
    padding: 10px 3px 10px 10px;
  }
}

@media only screen and (max-width: 768px) {
  .row {
    display: block;
    margin-top: 1.5rem;
  }
  .col1 {
    width: 80%;
    margin: 0 auto;
  }
  .col2 {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .row {
    display: flex;
    margin-top: 1.5rem;
  }
  .col1 {
    width: 33%;
    margin-right: 25px;
  }
  .col2 {
    width: 67%;
  }
}
</style>
